<template>
  <div class="project-page">
    <div class="btn-wrapper">
      <div @click="downloadTemplate" class="download-div" :class="!myUpload ? 'disable_icon' : ''">
        <i class="el-icon-download"></i>
        <span class="download-text">下载模板</span>
      </div>
      <el-button @click="handleAddBtn" :class="!myUpload ? 'disable_icon' : ''">新增</el-button>
      <el-upload class="upload-demo" :headers="{ token }" :on-success="onsuccess" ref="upload" :data="updata" :action="importUrl" :show-file-list="false">
        <el-button class="import-btn" @click="handleImportBtn" :class="!myUpload ? 'disable_icon' : ''">导入</el-button>
      </el-upload>
      <!-- <el-button @click="handleImportBtn">导入</el-button> -->
    </div>
    <div class="table-wrapper">
      <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#04153F', color: '#fff' }" :data="tableData">
        <!-- <el-table-column type="index" width="80" label="序号"></el-table-column> -->
        <!-- <el-table-column label="施工标段名称">{{sectionName}}</el-table-column> -->
        <el-table-column prop="name" label="分部工程名称"></el-table-column>
        <el-table-column prop="pierName" label="墩号名称"></el-table-column>
        <!-- <el-table-column prop="crossUp" label="上行截面位置"></el-table-column> -->
        <!-- <el-table-column prop="crossDown" label="下行截面位置"></el-table-column> -->
        <el-table-column prop="id" label="操作">
          <template slot-scope="scope">
            <span class="operate-txt" style="margin-right: 10px" @click="handleEdit(scope.row)" :class="!myOption ? 'disable_icon' : ''">修改</span>
            <span class="operate-txt" @click="handleDelete(scope.row)" :class="!myDel ? 'disable_icon' : ''">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <common-pagination v-show="tableData.length > 0" class="pageMain" :pageSize="pageSize" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </div>
    <!-- 新增 -->
    <el-dialog :title="operateType == 'add' ? '新增' : '修改'" :visible.sync="addDialogVisible" class="my-dialog" top="20vh" :before-close="handleDialogClose">
      <div class="add-wrapper">
        <el-form :model="operateForm" :rules="formRules" ref="operateForm" label-width="100px" class="my-form">
          <el-form-item label="施工标段名称" prop="sectionName" style="width: 100%">
            <el-input v-model.trim="operateForm.sectionName" placeholder="贺州至巴马高速公路（象州至来宾段）培森柳江特大桥施工质量监控" disabled></el-input>
          </el-form-item>
          <el-form-item label="分部工程" prop="name">
            <el-input v-model.trim="operateForm.name" placeholder="请输入分部工程"></el-input>
          </el-form-item>
          <el-form-item label="墩号" prop="pierName">
            <el-input v-model.trim="operateForm.pierName" placeholder="请输入墩号"></el-input>
          </el-form-item>
          <!-- <el-form-item label="上行截面位置" prop="crossUp">
                        <el-input v-model.trim="operateForm.crossUp" placeholder="请输入上行截面位置"></el-input>
                    </el-form-item> -->
          <!-- <el-form-item label="下行截面位置" prop="crossDown">
                        <el-input v-model.trim="operateForm.crossDown" placeholder="请输入下行截面位置"></el-input>
                    </el-form-item> -->
        </el-form>
        <div class="btn-footer">
          <el-button class="save-btn" @click="handleSave">保存</el-button>
          <el-button class="cancel-btn" @click="handleDialogClose">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
import axios from "axios";
export default {
  name: "project",
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      token: window.sessionStorage.getItem("token"),
      total: 0,
      pageSize: 10,
      currentPage: 1,
      // 表格数据
      tableData: [],
      addDialogVisible: false,
      operateForm: {},
      formRules: {
        // sectionName: [{ required: true, message: '请输入施工标段名称', trigger: 'blur' }],
        name: [{ required: true, message: "请输入分部工程", trigger: "blur" }],
        pierName: [{ required: true, message: "请输入墩号", trigger: "blur" }]
        // crossUp: [{ required: true, message: '请输入上行截面位置', trigger: 'blur' }],
        // crossDown: [{ required: true, message: '请输入下行界面位置', trigger: 'blur' }],
      },
      // 施工标段名称
      sectionName: "",
      // 操作类型 新增/编辑
      operateType: "",
      // 当前操作的行
      currentRow: {},
      // 导入时额外参数
      updata: {},
      // 导入url
      importUrl: ""
    };
  },
  mounted() {
    this.importUrl = `${this.baseURL}section/import/branch/${this.projectId}`;
    // this.getSectionName()
  },
  created() {
    this.getSectionName();
    this.getTableData();
  },
  methods: {
    // 获取施工标段名称
    getSectionName() {
      this.$axios.get(`${this.baseURL}section/info/${this.projectId}`).then((res) => {
        if (res.status == "200") {
          this.sectionName = res.data.data.name;
          this.$set(this.operateForm, "sectionName", res.data.data.name);
        }
      });
    },
    // 获取表格数据
    getTableData() {
      this.$axios.get(`${this.baseURL}section/branch/page/${this.projectId}?pageNum=${this.currentPage}&pageSize=${this.pageSize}`).then((res) => {
        console.log("获取表格数据", res);
        if (res.data.status == "200") {
          let detail = res.data.data;
          this.tableData = detail.records;
          this.total = detail.total;
        }
      });
    },
    // 下载模板
    downloadTemplate() {
      if (!this.myUpload) {
        this.$message.error("您没有下载权限！");
        return;
      }
      axios({
        url: `${this.baseURL}section/download`,
        method: "put",
        responseType: "blob",
        params: { type: 1 }, // 分部工程模板 传1
        headers: {
          token: this.token
        }
      })
        .then((res) => {
          console.log("导出模板", res);

          let a = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          let objectUrl = URL.createObjectURL(blob);
          let name = "分布工程导入模板";
          a.setAttribute("href", objectUrl);
          a.setAttribute("download", `${name}.xlsx`);
          a.click();
        })
        .catch((err) => {});
    },
    // 新增
    handleAddBtn() {
      if (!this.myUpload) {
        this.$message.error("您没有新增权限！");
        return;
      }
      this.operateType = "add";
      this.addDialogVisible = true;
    },
    // 保存
    handleSave() {
      this.$refs.operateForm.validate((valid) => {
        if (valid) {
          let data = {
            name: this.operateForm.name, // 名称
            pierName: this.operateForm.pierName, // 墩号名称
            projectId: this.projectId,
            id: this.currentRow.id
          };
          if (this.operateType == "add") {
            // 新增
            let str = data.name.replace(/\#/g, "%23");
            this.$axios.post(`${this.baseURL}section/branch/${this.projectId}?name=${str}&pierName=${data.pierName}`).then((res) => {
              console.log(res);
              if (res.data.status == "200") {
                this.$message.success("新增成功");
                this.operateForm = {};
                this.$set(this.operateForm, "sectionName", this.sectionName);
                this.addDialogVisible = false;
                this.getTableData();
              } else {
                this.$message.error(res.data.errMsg);
              }
            });
          } else {
            // 编辑
            this.$axios.put(`${this.baseURL}section/branch`, data).then((res) => {
              console.log(res);
              if (res.data.status == "200") {
                this.$message.success("编辑成功");
                this.operateForm = {};
                this.$set(this.operateForm, "sectionName", this.sectionName);
                this.addDialogVisible = false;
                this.getTableData();
              } else {
                this.$message.error(res.data.errMsg);
              }
            });
          }
        }
      });
    },
    // 编辑
    handleEdit(row) {
      if (!this.myOption) {
        this.$message.error("您没有修改权限！");
        return;
      }
      this.operateType = "edit";
      this.addDialogVisible = true;
      this.currentRow = row;
      this.$axios.get(`${this.baseURL}section/branch/info/${row.id}`).then((res) => {
        if (res.status == "200") {
          this.operateForm = res.data.data;
          this.$set(this.operateForm, "sectionName", this.sectionName);
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 删除
    handleDelete(row) {
      if (!this.myDel) {
        this.$message.error("您没有删除权限！");
        return;
      }
      this.$confirm("此信息可能被多处使用，删除导致其他数据不完整，确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        this.$axios.delete(`${this.baseURL}section/branch/${row.id}`).then((res) => {
          if (res.status == 200) {
            this.$message.success("删除成功");
            this.getTableData();
          } else {
            this.$message.error(res.errMsg);
          }
        });
      });
    },
    // 导入
    handleImportBtn() {
      if (!this.myUpload) {
        this.$message.error("您没有导入权限！");
        return;
      }
      this.updata = {
        projectId: this.projectId
      };
    },
    // 导入成功
    onsuccess(res, file, fileList) {
      // console.log(res, file, fileList)
      if (res.errCode == 200) {
        this.$message.success("导入成功");
        this.getTableData();
      } else {
        this.$message.error(res.errMsg);
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    // 关闭弹窗
    handleDialogClose() {
      this.addDialogVisible = false;
      this.$refs.operateForm.resetFields();
    }
  },
  components: {
    CommonPagination
  }
};
</script>

<style lang="scss" scoped>
.project-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  .btn-wrapper {
    display: flex;
    line-height: 32px;
    justify-content: flex-end;
    margin-bottom: 30px;
    .download-div:hover {
      cursor: pointer;
    }
    .download-div {
      display: flex;
      align-items: center;
      .download-text {
        font-size: 14px;
        color: #cbe1fc;
      }
      i {
        font-size: 20px;
        color: #2772f0;
      }
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .table-wrapper {
    flex: 1;
    overflow: hidden;
    .el-table {
      height: calc(100% - 50px);
      overflow: auto;
    }
  }
}
::v-deep .hui1 {
  background: #091d46 !important;
}
::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  text-align: center;
}
.common-page.pageMain {
  margin-top: 15px;
}
</style>

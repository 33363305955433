<template>
  <div class="point-manage-page">
    <div class="box center search-wrapper">
      <div class="box center search">
        <el-select v-model="typeId" placeholder="请选择监测指标">
          <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
        <el-select v-model="pierId" placeholder="请选择墩号">
          <el-option v-for="item in pierOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
        <el-select v-model="linkId" placeholder="请选择节段">
          <el-option v-for="item in linkOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
        <el-input v-model.trim="code" placeholder="请输入测点编号"></el-input>
        <el-button @click="handleSearch">查询</el-button>
        <el-button class="reset-btn" @click="handleResetClick">重置</el-button>
      </div>
      <div class="btn-wrapper">
        <div @click="downloadTemplate" class="download-div" :class="!myUpload ? 'disable_icon' : ''">
          <i class="el-icon-download"></i>
          <span class="download-text">下载模板</span>
        </div>
        <el-button @click="handleAddBtn" :class="!myUpload ? 'disable_icon' : ''">新增</el-button>
        <el-upload class="upload-demo" :headers="{ token }" :on-success="onsuccess" ref="upload" :data="updata" :action="importUrl" :show-file-list="false">
          <el-button class="import-btn" @click="handleImportBtn" :class="!myUpload ? 'disable_icon' : ''">导入</el-button>
        </el-upload>
      </div>
    </div>
    <div class="table-wrapper">
      <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#04153F', color: '#fff' }" :data="tableData">
        <el-table-column prop="code" label="测点编号" width="220px"></el-table-column>
        <el-table-column prop="type" label="监测指标" width="100" :formatter="formatType"></el-table-column>
        <el-table-column prop="profile" label="截面号"></el-table-column>
        <el-table-column prop="siteName" label="位置"></el-table-column>
        <el-table-column prop="site" label="位置描述"></el-table-column>
        <el-table-column prop="ordinate" label="纵坐标(m)" width="120"></el-table-column>
        <!-- <el-table-column prop="elevation" label="梁顶目标高程(m)" width="160"></el-table-column> -->
        <el-table-column prop="deflection" label="工况理论挠度(mm)" width="200"></el-table-column>
        <el-table-column prop="pierName" label="墩号"></el-table-column>
        <el-table-column prop="linkName" label="施工节段"></el-table-column>
        <el-table-column prop="id" label="操作">
          <template slot-scope="scope">
            <span class="operate-txt" style="margin-right: 10px" @click="handleEdit(scope.row)" :class="!myOption ? 'disable_icon' : ''">修改</span>
            <span class="operate-txt" v-show="scope.row.status != 1" @click="handleEnable(scope.row)" :class="!myOption ? 'disable_icon' : ''">启用</span>
            <span class="operate-txt" v-show="scope.row.status == 1" @click="handleDisable(scope.row)" :class="!myOption ? 'disable_icon' : ''">禁用</span>
          </template>
        </el-table-column>
      </el-table>
      <common-pagination v-show="tableData.length > 0" class="pageMain" :pageSize="pageSize" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </div>
    <!-- 新增/编辑 -->
    <el-dialog :title="operateType == 'add' ? '新增' : '修改'" :visible.sync="addDialogVisible" class="my-dialog" top="15vh" :before-close="handleDialogClose">
      <div class="add-wrapper">
        <el-form :model="operateForm" :rules="formRules" ref="operateForm" label-width="140px" class="my-form">
          <el-form-item label="施工标段名称" prop="sectionName" style="width: 100%">
            <el-input v-model.trim="operateForm.sectionName" placeholder="贺州至巴马高速公路（象州至来宾段）培森柳江特大桥施工质量监控" disabled></el-input>
          </el-form-item>
          <el-form-item label="测点编号" prop="code">
            <el-input v-model.trim="operateForm.code" placeholder="请输入测点编号"></el-input>
          </el-form-item>
          <el-form-item label="监测类型" prop="type">
            <el-select v-model="operateForm.type" placeholder="请选择监测类型" @change="handleTypeChangeClick">
              <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="截面号" prop="profileId">
            <el-select v-model="operateForm.profileId" placeholder="请选择截面">
              <el-option v-for="item in profileOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="位置" prop="siteId">
            <el-select v-model="operateForm.siteId" placeholder="请选择位置">
              <el-option v-for="item in siteOptions" :key="item.id" :label="item.site" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="梁顶目标高程(m)" prop="elevation">
                        <el-input v-model.trim="operateForm.elevation" placeholder="请输入梁顶目标高程"></el-input>
                    </el-form-item> -->
          <el-form-item label="工况理论挠度(mm)" prop="deflection">
            <el-input v-model.trim="operateForm.deflection" placeholder="请输入工况理论挠度"></el-input>
          </el-form-item>
          <el-form-item label="位置描述" prop="site">
            <el-input v-model.trim="operateForm.site" placeholder="位置描述"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn-footer">
          <el-button class="save-btn" @click="handleSave">保存</el-button>
          <el-button class="cancel-btn" @click="handleDialogClose">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
import axios from "axios";
export default {
  name: "pointManage",
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      token: window.sessionStorage.getItem("token"),
      // 分部工程
      branchId: "",
      // 施工节段
      linkId: "",
      linkOptions: [],
      typeId: "",
      // 墩号
      pierId: "",
      pierOptions: [],
      // 测点
      code: "",
      total: 0,
      currentPage: 1,
      pageSize: 10,
      // 表格数据
      tableData: [],
      // 操作类型
      operateType: "",
      addDialogVisible: false,
      operateForm: {},
      typeOptions: [],
      // 截面
      profileOptions: [],
      // 校验规则
      formRules: {
        code: [{ required: true, message: "请输入测点编号", trigger: "blur" }],
        type: [{ required: true, message: "请选择监测类型", trigger: "blur" }],
        profile: [{ required: true, message: "请选择截面号", trigger: "blur" }],
        siteId: [{ required: true, message: "请选择位置", trigger: "blur" }]
        // elevation: [{ required: true, message: '请输入梁顶目标高程', trigger: 'blur' }]
      },
      // 施工标段名称
      sectionName: "",
      // 当前操作行
      currentRow: {},
      updata: {},
      importUrl: "",
      // 位置
      siteOptions: []
    };
  },
  created() {
    this.getManualPointType();
    this.getAllLinkData();
    this.getSectionName();
    this.getAllPier();
    this.getAllProfileData();
  },
  mounted() {
    this.getTableData();
    // this.getAllSiteData()
    this.importUrl = `${this.baseURL}section/import/point/${this.projectId}`;
  },
  methods: {
    // 获取施工标段名称
    getSectionName() {
      this.$axios.get(`${this.baseURL}section/info/${this.projectId}`).then((res) => {
        if (res.status == "200") {
          this.sectionName = res.data.data.name;
          this.$set(this.operateForm, "sectionName", res.data.data.name);
        }
      });
    },
    // 获取所有节段
    getAllLinkData() {
      this.$axios.get(`${this.baseURL}section/link/${this.projectId}`).then((res) => {
        console.log("获取全部节段数据", res);
        let detail = res.data.data;
        this.linkOptions = detail;
      });
    },
    // 获取所有截面 人工
    getAllProfileData() {
      // type：1 人工测点 2 自动监测测点
      this.$axios.get(`${this.baseURL}section/profile/list/${this.projectId}?type=1`).then((res) => {
        console.log("获取全部截面管理数据", res);
        this.profileOptions = res.data.data;
      });
    },
    // 获取所有位置 人工
    getAllSiteData(val) {
      this.$axios.get(`${this.baseURL}section/point/site/${this.projectId}?type=${val}`).then((res) => {
        console.log("获取位置管理数据", res);
        this.siteOptions = res.data.data;
      });
    },
    // 根据监测类型获取位置下拉框数据
    handleTypeChangeClick(val) {
      this.$set(this.operateForm, "siteId", "");
      this.getAllSiteData(val);
    },
    // 获取所有墩号
    getAllPier() {
      this.$axios.get(`${this.baseURL}section/pier/${this.projectId}`).then((res) => {
        console.log("获取全部墩号数据", res);
        let detail = res.data.data;
        this.pierOptions = detail;
      });
    },
    // 获取人工 监测类型
    getManualPointType() {
      this.$axios.get(`${this.baseURL}section/point/type`).then((res) => {
        console.log("获取人工监测----监测类型--------------", res);
        if (res.data.status == "200") {
          this.typeOptions = res.data.data;
        }
      });
    },
    // 获取表格数据
    getTableData() {
      let path = `section/point/page?pageNum=${this.currentPage}&pageSize=${this.pageSize}&projectId=${this.projectId}&type=${this.typeId}&linkId=${this.linkId}&pierId=${this.pierId}&code=${this.code}`;
      this.$axios.get(`${this.baseURL}${path}`).then((res) => {
        console.log("获取人工测点数据", res);
        let detail = res.data.data;
        this.tableData = detail.records;
        this.total = detail.total;
      });
    },
    // 查询
    handleSearch() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 重置
    handleResetClick() {
      this.typeId = "";
      this.pierId = "";
      this.linkId = "";
      this.code = "";
      this.currentPage = 1;
      this.getTableData();
    },
    // 下载模板
    downloadTemplate() {
      if (!this.myUpload) {
        this.$message.error("您没有下载权限！");
        return;
      }
      axios({
        url: `${this.baseURL}section/download`,
        method: "put",
        responseType: "blob",
        params: { type: 5 }, // 人工测点模板 传5
        headers: {
          token: this.token
        }
      })
        .then((res) => {
          console.log("导出模板", res);

          let a = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          let objectUrl = URL.createObjectURL(blob);
          let name = "测点导入模板";
          a.setAttribute("href", objectUrl);
          a.setAttribute("download", `${name}.xlsx`);
          a.click();
        })
        .catch((err) => {});
    },
    // 新增
    handleAddBtn() {
      if (!this.myUpload) {
        this.$message.error("您没有新增权限！");
        return;
      }
      this.operateType = "add";
      this.$set(this.operateForm, "sectionName", this.sectionName);
      this.addDialogVisible = true;
      this.siteOptions = [];
    },
    // 查看详情
    getDetail(id) {
      this.$axios.get(`${this.baseURL}section/point/${id}`).then((res) => {
        console.log("查看详情--------", res);
        if (res.data.status == "200") {
          this.getAllSiteData(res.data.data.type);
          this.operateForm = res.data.data;
          this.$set(this.operateForm, "sectionName", this.sectionName);
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 编辑
    handleEdit(row) {
      if (!this.myOption) {
        this.$message.error("您没有修改权限！");
        return;
      }
      this.operateType = "edit";
      this.addDialogVisible = true;
      this.getDetail(row.id);
      this.currentRow = row;
    },
    // 修改状态
    editStatus(id, status) {
      this.$axios.put(`${this.baseURL}section/point/status/${id}?status=${status}`).then((res) => {
        if (res.status == "200") {
          this.$message.success("修改成功");
          this.getTableData();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 启用
    handleEnable(row) {
      if (!this.myOption) {
        this.$message.error("您没有修改权限！");
        return;
      }
      this.editStatus(row.id, 1);
    },
    // 禁用
    handleDisable(row) {
      if (!this.myOption) {
        this.$message.error("您没有修改权限！");
        return;
      }
      this.editStatus(row.id, 0);
    },
    // 保存
    handleSave() {
      this.$refs.operateForm.validate((valid) => {
        if (valid) {
          let data = {
            code: this.operateForm.code, // 测点编号
            type: this.operateForm.type, // 监测类型
            profileId: this.operateForm.profileId, // 截面
            siteId: this.operateForm.siteId, // 位置
            deflection: this.operateForm.deflection, // 工况理论挠度
            // elevation: this.operateForm.elevation, // 梁顶目标高程
            linkId: this.operateForm.linkId, // 节段
            ordinate: this.operateForm.ordinate, // 纵坐标
            projectId: this.projectId, // 桥梁id
            site: this.operateForm.site // 位置描述
          };
          if (this.operateType == "add") {
            data.status = 1; // 默认启用
            // 新增
            this.$axios.post(`${this.baseURL}section/point`, data).then((res) => {
              if (res.data.status == "200") {
                this.$message.success("新增成功");
                this.addDialogVisible = false;
                this.operateForm = {};
                this.$set(this.operateForm, "sectionName", this.sectionName);
                this.getTableData();
              } else {
                this.$message.error(res.data.errMsg);
              }
            });
          } else {
            // 编辑
            data.status = this.currentRow.status;
            data.id = this.currentRow.id;
            this.$axios.put(`${this.baseURL}section/point`, data).then((res) => {
              if (res.data.status == "200") {
                this.$message.success("修改成功");
                this.addDialogVisible = false;
                this.operateForm = {};
                this.$set(this.operateForm, "sectionName", this.sectionName);
                this.getTableData();
              } else {
                this.$message.error(res.data.errMsg);
              }
            });
          }
        }
      });
    },
    // 导入
    handleImportBtn() {
      if (!this.myUpload) {
        this.$message.error("您没有导入权限！");
        return;
      }
      this.updata = {
        projectId: this.projectId
      };
    },
    // 导入成功
    onsuccess(res, file, fileList) {
      // console.log(res, file, fileList)
      if (res.status == 200) {
        this.$message.success("导入成功");
        this.currentPage = 1;
        this.getTableData();
      } else {
        this.$message.error(res.errMsg);
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    // 关闭弹窗
    handleDialogClose() {
      this.addDialogVisible = false;
      this.$refs.operateForm.resetFields();
      this.operateForm = {};
    },
    // 格式化监测类型
    formatType(row, column) {
      let type = row.type;
      let str = "";
      this.typeOptions.forEach((item) => {
        if (item.id == type) {
          str = item.name;
        }
      });
      return str;
    }
  },
  components: {
    CommonPagination
  }
};
</script>

<style lang="scss" scoped>
::v-deep .my-dialog div.el-dialog {
  width: 630px !important;
}
.point-manage-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  .search-wrapper {
    line-height: 32px;
    justify-content: space-between;
    margin-bottom: 30px;
    .search .el-select {
      width: 180px;
      margin-right: 20px;
      ::v-deep .el-input {
        height: 32px;
      }
    }
    .btn-wrapper {
      display: flex;
      .download-div:hover {
        cursor: pointer;
      }
      .download-div {
        display: flex;
        align-items: center;
        .download-text {
          font-size: 14px;
          color: #cbe1fc;
        }
        i {
          font-size: 20px;
          color: #2772f0;
        }
      }
      .el-button {
        margin-left: 20px;
      }
    }
  }
  .table-wrapper {
    flex: 1;
    overflow: hidden;
    .el-table {
      height: calc(100% - 50px);
      overflow: auto;
    }
  }
}
::v-deep .hui1 {
  background: #091d46 !important;
}
::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  text-align: center;
}
.common-page.pageMain {
  margin-top: 15px;
}
::v-deep .search-wrapper div.el-input .el-input__inner {
  width: 180px;
  height: 32px;
  margin-right: 15px;
}
</style>

<template>
  <div class="working-page">
    <div class="btn-wrapper">
      <div @click="downloadTemplate" class="download-div" :class="!myUpload ? 'disable_icon' : ''">
        <i class="el-icon-download"></i>
        <span class="download-text">下载模板</span>
      </div>
      <el-button @click="handleAddBtn" :class="!myUpload ? 'disable_icon' : ''">新增</el-button>
      <el-upload class="upload-demo" :headers="{ token }" :on-success="onsuccess" ref="upload" :data="updata" :action="importUrl" :show-file-list="false">
        <el-button class="import-btn" @click="handleImportBtn" :class="!myUpload ? 'disable_icon' : ''">导入</el-button>
      </el-upload>
      <!-- <el-button @click="handleImportBtn">导入</el-button> -->
    </div>
    <div class="table-wrapper">
      <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#04153F', color: '#fff' }" :data="tableData">
        <!-- <el-table-column type="index" width="80" label="序号"></el-table-column> -->
        <!-- <el-table-column label="施工标段名称">{{sectionName}}</el-table-column> -->
        <el-table-column prop="name" label="工况名称"></el-table-column>
        <el-table-column prop="orders" label="工序编号"></el-table-column>
        <el-table-column prop="linkName" label="所属节段">
          <template slot-scope="scope">
            <span>{{ formatLinkName(scope.row.linkName) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="last" label="测量最终工况" :formatter="formatIsLast"></el-table-column>
        <el-table-column prop="id" label="操作">
          <template slot-scope="scope">
            <span class="operate-txt" style="margin-right: 10px" @click="handleEdit(scope.row)" :class="!myOption ? 'disable_icon' : ''">修改</span>
            <span class="operate-txt" @click="handleDelete(scope.row)" :class="!myDel ? 'disable_icon' : ''">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <common-pagination v-show="tableData.length > 0" class="pageMain" :pageSize="pageSize" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </div>
    <!-- 新增 -->
    <el-dialog :title="operateType == 'add' ? '新增' : '编辑'" :visible.sync="addDialogVisible" class="my-dialog" top="20vh" :before-close="handleDialogClose">
      <div class="add-wrapper">
        <el-form :model="operateForm" :rules="formRules" ref="operateForm" label-width="110px" class="my-form">
          <el-form-item label="施工标段名称" prop="sectionName">
            <el-input v-model="operateForm.sectionName" placeholder="贺州至巴马高速公路（象州至来宾段）培森柳江特大桥施工质量监控" disabled class="my-input"></el-input>
          </el-form-item>
          <el-form-item label="工况" prop="name">
            <el-input v-model.trim="operateForm.name" placeholder="请输入工况名称"></el-input>
          </el-form-item>
          <el-form-item label="工序编号" prop="orders">
            <el-input v-model.trim="operateForm.orders" placeholder="请输入工序编号"></el-input>
          </el-form-item>
          <el-form-item label="所属节段" prop="linkIds">
            <el-select v-model="operateForm.linkIds" placeholder="请选择所属节段" multiple collapse-tags @change="handleLinkChange">
              <el-option v-for="item in linksOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
            <span class="select-all-wrapper">
              <el-checkbox v-model="isShowAll" @change="handleSelectAllChange"> 全选 </el-checkbox>
            </span>
          </el-form-item>
          <el-form-item label="测量最终工况" prop="last">
            <el-select v-model="operateForm.last" placeholder="请选择">
              <el-option v-for="item in isLastOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="btn-footer">
          <el-button class="save-btn" @click="handleSave">保存</el-button>
          <el-button class="cancel-btn" @click="handleDialogClose">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
import axios from "axios";
export default {
  name: "project",
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      token: window.sessionStorage.getItem("token"),
      total: 0,
      currentPage: 1,
      pageSize: 10,
      // 表格数据
      tableData: [],
      addDialogVisible: false,
      operateForm: {},
      formRules: {
        // sectionName: [{ required: true, message: '请输入施工标段名称', trigger: 'blur' }],
        name: [{ required: true, message: "请输入节段", trigger: "blur" }],
        // orders: [{ required: true, message: '请输入工序编号', trigger: 'blur' }]
        orders: [{ validator: this.isNumber, required: true, trigger: "blur" }],
        last: [{ required: true, message: "请选择测量最终工况", trigger: "blur" }]
      },
      // 施工标段名称
      sectionName: "",
      // 操作类型 新增/编辑
      operateType: "",
      // 当前操作行
      currentRow: {},
      // 导入时额外参数
      updata: {},
      // 导入url
      importUrl: "",
      // 节段
      linksOptions: [],
      // 是否测量最终工况
      isLastOptions: [
        {
          id: 0,
          name: "否"
        },
        {
          id: 1,
          name: "是"
        }
      ],
      // 节段默认全选
      isShowAll: true,
      // 节段所有id集合
      linkIds: []
    };
  },
  created() {
    this.getSectionName();
    this.getTableData();
  },
  mounted() {
    this.importUrl = `${this.baseURL}section/import/working/${this.projectId}`;
    this.getAllLinkData();
  },
  methods: {
    // 判断是否是数字类型
    isNumber(rule, value, callback) {
      if (value === "") {
        return callback();
      }
      const r = /^\+?[1-9][0-9]*$/;
      if (!r.test(value)) {
        return callback(new Error("请输入正整数"));
      } else {
        return callback();
      }
    },
    // 获取施工标段名称
    getSectionName() {
      this.$axios.get(`${this.baseURL}section/info/${this.projectId}`).then((res) => {
        if (res.status == "200") {
          this.sectionName = res.data.data.name;
          this.$set(this.operateForm, "sectionName", res.data.data.name);
        }
      });
    },
    // 获取所有节段
    getAllLinkData() {
      this.$axios.get(`${this.baseURL}section/link/${this.projectId}`).then((res) => {
        console.log("获取全部节段数据", res);
        let detail = res.data.data;
        this.linksOptions = detail;
        this.linkIds = [];
        detail.forEach((item) => {
          this.linkIds.push(item.id);
        });
        this.$set(this.operateForm, "linkIds", this.linkIds);
      });
    },
    // 获取表格数据
    getTableData() {
      this.$axios.get(`${this.baseURL}section/working/page/${this.projectId}?pageNum=${this.currentPage}&pageSize=${this.pageSize}`).then((res) => {
        console.log("获取表格数据--------", res);
        let detail = res.data.data;
        this.tableData = detail.records;
        this.total = detail.total;
      });
    },
    // 下载模板
    downloadTemplate() {
      if (!this.myUpload) {
        this.$message.error("您没有下载权限！");
        return;
      }
      axios({
        url: `${this.baseURL}section/download`,
        method: "put",
        responseType: "blob",
        params: { type: 4 }, // 工况模板 传4
        headers: {
          token: this.token
        }
      })
        .then((res) => {
          console.log("导出模板", res);

          let a = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          let objectUrl = URL.createObjectURL(blob);
          let name = "工况导入模板";
          a.setAttribute("href", objectUrl);
          a.setAttribute("download", `${name}.xlsx`);
          a.click();
        })
        .catch((err) => {});
    },
    // 新增
    handleAddBtn() {
      if (!this.myUpload) {
        this.$message.error("您没有新增权限！");
        return;
      }
      this.operateType = "add";
      this.$set(this.operateForm, "last", 0);
      this.addDialogVisible = true;
      this.isShowAll = true;
      this.$set(this.operateForm, "linkIds", this.linkIds);
    },
    // 新增 全选
    handleSelectAllChange(val) {
      console.log("全选", val);
      if (val) {
        this.$set(this.operateForm, "linkIds", this.linkIds);
      } else {
        this.$set(this.operateForm, "linkIds", []);
      }
    },
    // 所属节段下拉选择切换
    handleLinkChange(val) {
      // console.log('切换选择下拉框', val)
      // console.log('下拉选择数据的长度',val.length,'所有节段数据的长度', this.linkIds.length)
      if (val.length < this.linkIds.length) {
        this.isShowAll = false;
      } else {
        this.isShowAll = true;
      }
    },
    // 编辑
    handleEdit(row) {
      if (!this.myOption) {
        this.$message.error("您没有修改权限！");
        return;
      }
      this.currentRow = row;
      this.operateType = "edit";
      this.addDialogVisible = true;
      // 获取除节段外的数据
      this.$axios.get(`${this.baseURL}section/working/info/${row.id}`).then((res) => {
        if (res.status == "200") {
          this.operateForm = res.data.data;
          this.$set(this.operateForm, "sectionName", this.sectionName);
        } else {
          this.$message.error(res.errMsg);
        }
      });
      // 获取节段下拉框详情
      this.$axios.get(`${this.baseURL}section/working/link/${row.id}`).then((res) => {
        console.log("获取工况所属节段，编辑弹窗里下拉框数据", res);
        if (res.data.status == "200") {
          let detail = res.data.data;
          if (detail.length) {
            let arr = [];
            detail.forEach((item) => {
              arr.push(item.linkId);
            });
            setTimeout(() => {
              this.$set(this.operateForm, "linkIds", arr);
            }, 100);
          }
          this.isShowAll = detail.length == this.linkIds.length;
        }
      });
    },
    // 保存
    handleSave() {
      this.$refs.operateForm.validate((valid) => {
        if (valid) {
          let data = {
            name: this.operateForm.name,
            projectId: this.projectId,
            orders: this.operateForm.orders,
            linkIds: this.operateForm.linkIds,
            last: this.operateForm.last
          };
          if (this.operateType == "add") {
            console.log(this.operateForm.linkIds);
            // 新增
            let str = data.name.replace(/\#/g, "%23");
            this.$axios.post(`${this.baseURL}section/working/${this.projectId}?name=${str}&orders=${data.orders}&last=${data.last}`, data.linkIds).then((res) => {
              if (res.data.status == "200") {
                this.$message.success("新增成功");
                this.addDialogVisible = false;
                this.operateForm = {};
                this.$set(this.operateForm, "sectionName", this.sectionName);
                this.getTableData();
              } else {
                this.$message.error(res.data.errMsg);
              }
            });
          } else {
            // 编辑
            data.id = this.currentRow.id;
            this.$axios.put(`${this.baseURL}section/working`, data).then((res) => {
              if (res.data.status == "200") {
                this.$message.success("修改成功");
                this.addDialogVisible = false;
                this.operateForm = {};
                this.$set(this.operateForm, "sectionName", this.sectionName);
                this.getTableData();
              } else {
                this.$message.error(res.data.errMsg);
              }
            });
          }
        }
      });
    },
    // 删除
    handleDelete(row) {
      if (!this.myDel) {
        this.$message.error("您没有删除权限！");
        return;
      }
      this.$confirm("此信息可能被多处使用，删除导致其他数据不完整，确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        this.$axios.delete(`${this.baseURL}section/working/${row.id}`).then((res) => {
          if (res.status == 200) {
            this.$message.success("删除成功");
            this.getTableData();
          } else {
            this.$message.error(res.errMsg);
          }
        });
      });
    },
    // 导入
    handleImportBtn() {
      if (!this.myUpload) {
        this.$message.error("您没有导入权限！");
        return;
      }
      this.updata = {
        projectId: this.projectId
      };
    },
    // 导入成功
    onsuccess(res, file, fileList) {
      // console.log(res, file, fileList)
      if (res.errCode == 200) {
        this.$message.success("导入成功");
        this.getTableData();
      } else {
        this.$message.error(res.errMsg);
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    // 关闭弹窗
    handleDialogClose() {
      this.addDialogVisible = false;
      this.$refs.operateForm.resetFields();
    },
    // 格式化 测量最终工况
    formatIsLast(row, column) {
      let num = row.last;
      let str = "";
      this.isLastOptions.forEach((item) => {
        if (item.id == num) {
          str = item.name;
        }
      });
      return str;
    },
    // 格式化 所属节段 最多显示3个，超过则...
    formatLinkName(data) {
      let detail = "";
      if (data) {
        let str = data.split(",");
        if (str.length > 3) {
          str = str.filter((item, index) => {
            return index < 3;
          });
          detail = str.join(",") + "...";
        } else {
          detail = str.join(",");
        }
        return detail;
      }
    }
  },
  components: {
    CommonPagination
  }
};
</script>

<style lang="scss" scoped>
.working-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  .btn-wrapper {
    display: flex;
    line-height: 32px;
    justify-content: flex-end;
    margin-bottom: 30px;
    .download-div:hover {
      cursor: pointer;
    }
    .download-div {
      display: flex;
      align-items: center;
      i {
        font-size: 20px;
        color: #2772f0;
      }
      .download-text {
        font-size: 14px;
        color: #cbe1fc;
      }
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .table-wrapper {
    flex: 1;
    overflow: hidden;
    .el-table {
      height: calc(100% - 50px);
      overflow: auto;
    }
  }
}
::v-deep .hui1 {
  background: #091d46 !important;
}
::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  text-align: center;
}
.common-page.pageMain {
  margin-top: 15px;
}
::v-deep .el-checkbox__inner {
  background-color: #2667db !important;
  border-color: #2667db !important;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #2772f0 !important;
  border-color: #2772f0 !important;
}
.select-all-wrapper {
  font-size: 12px;
  margin-left: 20px;
  ::v-deep span.el-checkbox__label {
    font-size: 12px;
  }
}
</style>

<template>
  <div class="pier-page">
    <div class="box center search-wrapper">
      <div class="box center search">
        <el-select v-model="monitorType" placeholder="请选择监测类型" @change="handleMonitorTypeChange">
          <el-option v-for="item in monitorTypeOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
        <el-select v-model="pointType" placeholder="请选择监测指标" @change="handlePointTypeChange">
          <el-option v-for="item in typeOptionsSearch" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </div>
      <div class="btn-wrapper">
        <div @click="downloadTemplate" class="download-div" :class="!myUpload ? 'disable_icon' : ''">
          <i class="el-icon-download"></i>
          <span class="download-text">下载模板</span>
        </div>
        <el-button @click="handleAddBtn" :class="!myUpload ? 'disable_icon' : ''">新增</el-button>
        <el-upload class="upload-demo" :headers="{ token }" :on-success="onsuccess" ref="upload" :data="updata" :action="importUrl" :show-file-list="false">
          <el-button class="import-btn" @click="handleImportBtn" :class="!myUpload ? 'disable_icon' : ''">导入</el-button>
        </el-upload>
      </div>
    </div>
    <div class="table-wrapper">
      <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#04153F', color: '#fff' }" :data="tableData">
        <!-- <el-table-column type="index" width="80" label="序号"></el-table-column> -->
        <el-table-column prop="type" label="监测类型" :formatter="formatType"></el-table-column>
        <el-table-column prop="quotaName" label="监测指标"></el-table-column>
        <el-table-column prop="site" label="位置"></el-table-column>
        <el-table-column prop="id" label="操作">
          <template slot-scope="scope">
            <span class="operate-txt" style="margin-right: 10px" @click="handleEdit(scope.row)" :class="!myOption ? 'disable_icon' : ''">修改</span>
            <span class="operate-txt" @click="handleDelete(scope.row)" :class="!myDel ? 'disable_icon' : ''">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <common-pagination v-show="tableData.length > 0" class="pageMain" :pageSize="pageSize" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </div>
    <!-- 新增 -->
    <el-dialog :title="operateType == 'add' ? '新增' : '修改'" :visible.sync="addDialogVisible" class="my-dialog" top="20vh" :before-close="handleDialogClose">
      <div class="add-wrapper">
        <el-form :model="operateForm" :rules="formRules" ref="operateForm" label-width="80px" class="my-form">
          <el-form-item label="监测类型" prop="type">
            <el-select v-model="operateForm.type" placeholder="请选择监测类型" @change="handleTypeChange">
              <el-option v-for="item in monitorTypeOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="监测指标" prop="quota">
            <el-select v-model="operateForm.quota" placeholder="请选择监测类型">
              <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="位置" prop="site">
            <el-input v-model.trim="operateForm.site" placeholder="请输入位置"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn-footer">
          <el-button class="save-btn" @click="handleSave">保存</el-button>
          <el-button class="cancel-btn" @click="handleDialogClose">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
import axios from "axios";
export default {
  name: "project",
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      token: window.sessionStorage.getItem("token"),
      pageSize: 10,
      total: 0,
      currentPage: 1,
      addDialogVisible: false,
      operateForm: {},
      // 表格数据
      tableData: [],
      // 分部工程
      branchOptions: [],
      // 标段名称
      sectionName: "",
      // 监测类型
      monitorType: "",
      monitorTypeOptions: [
        {
          id: 1,
          name: "人工监测"
        },
        {
          id: 2,
          name: "自动化监测（主梁）"
        },
        {
          id: 3,
          name: "自动化监测（墩）"
        }
      ],
      // 方向
      directionOptions: [
        {
          id: 1,
          name: "大里程方向"
        },
        {
          id: 2,
          name: "小里程方向"
        }
      ],
      // 监测指标
      pointType: "",
      typeOptions: [],
      typeOptionsSearch: [],
      // 人工检测时 监测指标
      manualType: [],
      // 校验规则
      formRules: {
        type: [{ required: true, message: "请选择监测类型", trigger: "blur" }],
        quota: [{ required: true, message: "请选择监测类型", trigger: "blur" }],
        site: [{ required: true, message: "请输入位置", trigger: "blur" }]
      },
      // 操作类型 新增/编辑
      operateType: "",
      // 当前操作行
      currentRow: {},
      // 导入时额外参数
      updata: {},
      // 导入url
      importUrl: ""
    };
  },
  created() {
    this.getManualPointType();
    this.getTableData();
    this.getSectionName();
  },
  mounted() {
    this.importUrl = `${this.baseURL}section/import/site/${this.projectId}`;
  },
  methods: {
    // 获取施工标段名称
    getSectionName() {
      this.$axios.get(`${this.baseURL}section/info/${this.projectId}`).then((res) => {
        if (res.status == "200") {
          console.log("执行施工标段-----------------------");
          this.sectionName = res.data.data.name;
          this.$set(this.operateForm, "sectionName", res.data.data.name);
        }
      });
    },
    // 获取人工 监测类型
    getManualPointType() {
      this.$axios.get(`${this.baseURL}section/point/type`).then((res) => {
        console.log("获取人工监测----监测类型--------------", res);
        if (res.data.status == "200") {
          this.manualType = res.data.data;
        }
      });
    },
    // 获取表格数据
    getTableData() {
      this.$axios.get(`${this.baseURL}section/site/page/${this.projectId}?pageNum=${this.currentPage}&pageSize=${this.pageSize}&quota=${this.pointType}&type=${this.monitorType}`).then((res) => {
        console.log("获取位置管理数据", res);
        let detail = res.data.data;
        this.tableData = detail.records;
        this.total = detail.total;
      });
    },
    // 下载模板
    downloadTemplate() {
      if (!this.myUpload) {
        this.$message.error("您没有权限！");
        return;
      }
      axios({
        url: `${this.baseURL}section/download`,
        method: "put",
        responseType: "blob",
        params: { type: 8 }, // 测点位置模板 传8
        headers: {
          token: this.token
        }
      })
        .then((res) => {
          console.log("导出模板", res);

          let a = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          let objectUrl = URL.createObjectURL(blob);
          let name = "测点位置管理导入模板";
          a.setAttribute("href", objectUrl);
          a.setAttribute("download", `${name}.xlsx`);
          a.click();
        })
        .catch((err) => {});
    },
    // 新增
    handleAddBtn() {
      if (!this.myUpload) {
        this.$message.error("您没有权限！");
        return;
      }
      this.operateType = "add";
      this.addDialogVisible = true;
      this.typeOptions = [];
    },
    // 编辑
    handleEdit(row) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      if (row.type == 1) {
        this.typeOptions = this.manualType;
      } else {
        this.getAutoPointData();
      }
      this.operateType = "edit";
      this.addDialogVisible = true;
      this.currentRow = row;
      // console.log('当前编辑行', row)
      this.$axios.get(`${this.baseURL}section/site/info/${row.id}`).then((res) => {
        if (res.status == "200") {
          this.operateForm = res.data.data;
          this.$set(this.operateForm, "sectionName", this.sectionName);
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 保存
    handleSave() {
      this.$refs.operateForm.validate((valid) => {
        if (valid) {
          let data = {
            type: this.operateForm.type, // 监测类型
            quota: this.operateForm.quota, // 监测指标
            site: this.operateForm.site, // 位置
            projectId: this.projectId // 桥梁id
          };
          if (this.operateType == "add") {
            // 新增
            this.$axios.post(`${this.baseURL}section/site/add`, data).then((res) => {
              if (res.status == "200") {
                this.$message.success("新增成功");
                this.addDialogVisible = false;
                this.operateForm = {};
                this.$set(this.operateForm, "sectionName", this.sectionName);
                this.getTableData();
              } else {
                this.$message.error(res.errMsg);
              }
            });
          } else {
            data.id = this.currentRow.id; // 操作行id
            // 编辑
            this.$axios.put(`${this.baseURL}section/site/update`, data).then((res) => {
              if (res.status == "200") {
                this.$message.success("编辑成功");
                this.addDialogVisible = false;
                this.operateForm = {};
                this.$set(this.operateForm, "sectionName", this.sectionName);
                this.getTableData();
              } else {
                this.$message.error(res.errMsg);
              }
            });
          }
        }
      });
    },
    // 删除
    handleDelete(row) {
      if (!this.myDel) {
        this.$message.error("您没有权限！");
        return;
      }
      this.currentRow = row;
      this.$confirm("此信息可能被多处使用，删除导致其他数据不完整，确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        this.$axios.delete(`${this.baseURL}section/site/del/${row.id}`).then((res) => {
          if (res.status == 200) {
            this.$message.success("删除成功");
            this.getTableData();
          } else {
            this.$message.error(res.errMsg);
          }
        });
      });
    },
    // 导入
    handleImportBtn() {
      if (!this.myUpload) {
        this.$message.error("您没有权限！");
        return;
      }
      this.updata = {
        projectId: this.projectId
      };
    },
    // 导入成功
    onsuccess(res, file, fileList) {
      // console.log(res, file, fileList)
      if (res.errCode == 200) {
        this.$message.success("导入成功");
        this.getTableData();
      } else {
        this.$message.error(res.errMsg);
      }
    },
    // 自动化监测时 监测指标数据
    getAutoPointData() {
      this.$axios.post(`${this.baseURL}section/target/type`).then((res) => {
        console.log("自动化监测-获取监测指标数据", res);
        this.typeOptions = res.data.data;
      });
    },
    // 切换监测类型 获取监测指标
    handleTypeChange(val) {
      this.$set(this.operateForm, "quota", "");
      if (val == 1) {
        this.typeOptions = this.manualType;
      } else {
        this.getAutoPointData();
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    // 关闭弹窗
    handleDialogClose() {
      this.addDialogVisible = false;
      this.$refs.operateForm.resetFields();
    },
    // 模糊查询 监测类型
    handleMonitorTypeChange(val) {
      this.pointType = "";
      if (val == 1) {
        this.typeOptionsSearch = this.manualType;
      } else {
        this.$axios.post(`${this.baseURL}section/target/type`).then((res) => {
          this.typeOptionsSearch = res.data.data;
        });
      }
      this.getTableData();
    },
    // 模糊查询 监测指标
    handlePointTypeChange() {
      this.getTableData();
    },
    // 格式化监测类型
    formatType(row, column) {
      let type = row.type;
      let str = "";
      this.monitorTypeOptions.forEach((item) => {
        if (item.id == type) {
          str = item.name;
        }
      });
      return str;
    }
  },
  components: {
    CommonPagination
  }
};
</script>

<style lang="scss" scoped>
::v-deep .my-dialog div.el-dialog {
  width: 410px !important;
}
.pier-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  .search-wrapper {
    line-height: 32px;
    justify-content: space-between;
    margin-bottom: 30px;
    .search .el-select {
      width: 180px;
      margin-right: 20px;
      ::v-deep .el-input {
        height: 32px;
      }
    }
    .btn-wrapper {
      display: flex;
      .download-div:hover {
        cursor: pointer;
      }
      .download-div {
        display: flex;
        align-items: center;
        .download-text {
          font-size: 14px;
          color: #cbe1fc;
        }
        i {
          font-size: 20px;
          color: #2772f0;
        }
      }
      .el-button {
        margin-left: 20px;
      }
    }
  }
  .table-wrapper {
    flex: 1;
    overflow: hidden;
    .el-table {
      height: calc(100% - 50px);
      overflow: auto;
    }
  }
}
::v-deep .hui1 {
  background: #091d46 !important;
}
::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  text-align: center;
}
.common-page.pageMain {
  margin-top: 15px;
}
</style>

<template>
    <div class="progress-page">
        <div class="left-wrapper">
            <div class="left-menu">
                <div class="title">
                    <el-divider class="my-divider" direction="vertical"></el-divider>
                    施工信息分类
                </div>
                <ul class="list-ul">
                    <li 
                        v-for="item in infoList" 
                        :key="item.id" 
                        :class="item.id == active ? 'active-li' : '' "
                        @click="handleSortClick(item)"
                    >
                        {{item.name}}
                    </li>
                </ul>
            </div>
        </div>
        <div class="right-wrapper">
            <!-- 施工标段管理 -->
            <section-page v-if="active == 1"></section-page>
            <!-- 分部工程管理 -->
            <project v-if="active == 2"></project>
            <!-- 墩号管理 -->
            <!-- <pier-number v-show="active == 3"></pier-number> -->
            <!-- 节段管理 -->
            <segment v-if="active == 4"></segment>
            <!-- 工况管理 -->
            <work-condition v-if="active == 5"></work-condition>
            <!-- 人工测点管理 -->
            <point-manage v-if="active == 6"></point-manage>
            <!-- 截面管理 -->
            <section-manage v-if="active == 7"></section-manage>
            <!-- 测点位置管理 -->
            <position-manage v-if="active == 8"></position-manage>
            <!-- 自动化监测设置 -->
            <auto-monitor v-if="active == 9"></auto-monitor>
        </div>
    </div>
</template>

<script>
import SectionPage from './components/Section'
import Project from './components/Project'
// import PierNumber from './components/PierNumber'
import Segment from './components/Segment'
import WorkCondition from './components/WorkCondition'
import PointManage from './components/PointManage'
import SectionManage from './components/SectionManage'
import PositionManage from './components/PositionManage'
import AutoMonitor from './components/AutoMonitor'
export default {
    name: 'progressPage',
    data() {
        return {
            // 施工信息分类列表
            infoList: [
                {
                    id: 1,
                    name: '施工标段管理'
                },
                {
                    id: 2,
                    name: '分部工程管理'
                },
                // {
                //     id: 3,
                //     name: '墩号管理'
                // },
                {
                    id: 4,
                    name: '节段管理'
                },
                {
                    id: 7,
                    name: '截面管理'
                },
                {
                    id: 5,
                    name: '工况管理'
                },
                {
                    id: 8,
                    name: '测点位置管理'
                },
                {
                    id: 6,
                    name: '人工测点管理'
                },
                {
                    id: 9,
                    name: '自动化监测设置'
                }
            ],
            // 默认选中第一个
            active: ''
        }
    },
    created() {
        setTimeout(() => {
            this.active = this.infoList[0].id
        }, 300);
    },
    methods: {
        // 点击施工信息分类
        handleSortClick(item) {
            this.active = item.id
            console.log(item.id)
        }
    },
    components: {
        SectionPage,
        Project,
        // PierNumber,
        Segment,
        WorkCondition,
        PointManage,
        SectionManage,
        PositionManage,
        AutoMonitor
    }
}
</script>

<style scoped lang="scss">
    .progress-page {
        width: 100%;
        height: calc(100% - 85px) !important;
        display: flex;
        .left-wrapper {
            width: 20%;
            height: 100%;
            .left-menu {
                width: 90%;
                height: 100%;
                padding: 30px;
                background: linear-gradient(180deg,rgba(4,85,213,0.10) 1%, rgba(19,18,68,0.65));
                border: 1px solid #2667db;
                font-size: 14px;
                text-align: center;
                .title {
                    text-align: left;
                    margin-bottom: 15px;
                    .my-divider {
                        background: #2667db;
                    }
                }
                .list-ul {
                    li {
                        height: 40px;
                        line-height: 40px;
                        margin-bottom: 15px;
                        background: rgba(50,116,255,0.24);
                        border: 1px solid #2157bd;
                    }
                    li:hover {
                        cursor: pointer;
                    }
                    li.active-li {
                        background: rgba(50,116,255,0.80);
                    }
                }
            }
        }
        .right-wrapper {
            flex: 1;
            background: linear-gradient(180deg,rgba(4,85,213,0.10) 1%, rgba(19,18,68,0.65));
            border: 1px solid #2667db;
        }
    }
    ::v-deep .my-dialog .el-dialog {
        width: 610px !important;
        border: none;
        background: linear-gradient(0deg,#0a173d 2%, #0d3772);
        .el-dialog__header {
            border-bottom: none;
            .el-dialog__title {
                font-size: 16px;
                font-weight: 500;
                color: rgba(255,255,255,0.86);
            }
        }
        .el-dialog__body {
            padding: 0 20px 40px 20px;
            color: #fff;
            .add-wrapper {
                border-top: 1px solid rgba(204,218,255,0.3);
                padding-top: 32px;
                .el-form-item__label {
                    font-size: 12px !important;
                }
                .el-input.is-disabled {
                    width: 100% !important;
                    .el-input__inner {
                        font-size: 12px !important;
                        background: rgba(35,51,97,0.70) !important;
                        border: 1px solid #2c487b;
                    }
                }
                .el-input .el-input__inner {
                    background: #0c265a;
                    border: 1px solid #0e43a3;
                }
                .btn-footer {
                    display: flex;
                    justify-content: center;
                    margin-top: 48px;
                    text-align: center;
                    .el-button {
                        width: 88px;
                        height: 32px;
                        line-height: 32px;
                        padding: 0;
                        margin: 0;
                        font-size: 12px;
                        font-weight: 500;
                    }
                    .save-btn {
                        margin-right: 16px;
                    }
                    .cancel-btn {
                        margin-left: 20px;
                        background: none;
                        border: 1px solid #2667db;
                    }
                }
            }
        }
    }
</style>

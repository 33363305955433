<template>
  <div class="construction-section">
    <div class="btn-wrapper">
      <el-button @click="handleSaveBtn" :class="!myOption ? 'disable_icon' : ''">保存</el-button>
      <el-button class="cancel-btn" @click="handleCancelBtn" :class="!myOption ? 'disable_icon' : ''">取消</el-button>
    </div>
    <div class="content-wrapper">
      <el-form :model="operateForm" :rules="formRules" ref="operateForm" label-width="150px" class="my-form">
        <el-form-item label="施工标段名称" prop="name" style="width: 60%">
          <el-input v-model.trim="operateForm.name" placeholder="请输入标段名称（例如：贺州至巴马高速公路（象州至来宾段）培森柳江特大桥施工质量监控）"></el-input>
        </el-form-item>
        <el-form-item label="合同编号" prop="contractCode" style="width: 35%">
          <el-input v-model.trim="operateForm.contractCode" placeholder="请输入合同编号（例如：GB50026-2020）"></el-input>
        </el-form-item>
        <el-form-item label="施工测量标准" prop="standard" style="width: 35%">
          <el-input v-model.trim="operateForm.standard" placeholder="请输入标准名称（例如：GB50026-2020）"></el-input>
        </el-form-item>
        <el-form-item label="大里程方向" prop="mileageBig" style="width: 35%">
          <el-input v-model.trim="operateForm.mileageBig" placeholder="请输入大里程方向"></el-input>
        </el-form-item>
        <el-form-item label="小里程方向" prop="mileageSmall" style="width: 35%">
          <el-input v-model.trim="operateForm.mileageSmall" placeholder="请输入小里程方向"></el-input>
        </el-form-item>
      </el-form>
      <div class="upload-wrapper">
        <span class="label-name"><span class="tips-text">*</span>桥梁图例</span>
        <el-upload class="upload-demo" :headers="{ token }" :on-success="onSuccess" :before-upload="beforeUpload" ref="upload" accept=".png" :action="baseURL + 'sys/sysImages/legend'" :show-file-list="false">
          <i class="el-icon-plus"></i>
        </el-upload>
        <img v-if="imgSrc" :src="imgSrc" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sectionPage",
  data() {
    return {
      operateForm: {},
      projectId: sessionStorage.getItem("projectId"),
      token: window.sessionStorage.getItem("token"),
      imgSrc: "",
      imageId: "",
      formRules: {
        name: [{ required: true, message: "请输入标段名称", trigger: "blur" }],
        contractCode: [{ required: true, message: "请输入合同编号", trigger: "blur" }],
        standard: [{ required: true, message: "请输入标准名称", trigger: "blur" }],
        mileageBig: [{ required: true, message: "请输入大里程方向", trigger: "blur" }],
        mileageSmall: [{ required: true, message: "请输入小里程方向", trigger: "blur" }]
      }
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取施工标段
    getData() {
      this.$axios.get(`${this.baseURL}section/info/${this.projectId}`).then((res) => {
        if (res.status == "200") {
          console.log("获取施工标段详情", res);
          this.operateForm = res.data.data;
          this.getImage(res.data.data.imageId);
        }
      });
    },
    // 获取图片流
    getImage(id) {
      this.imgSrc = `${this.baseURL}sys/sysImages/img/${id}`;
    },
    // 上传桥梁图例
    onSuccess(res, file, fileList) {
      // console.log(res, file, fileList)
      if (res.status == 200) {
        this.$message.success("上传成功");
        this.imageId = res.data;
        this.getImage(res.data);
      } else {
        this.$message.error(res.errMsg);
      }
    },
    // 上传之前
    beforeUpload(file) {
      console.log(file.type);
      const fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isCorrectType = fileType === "png";
      if (!isCorrectType) {
        this.$message.error("文件格式不正确，请上传.png文件");
      }
      return isCorrectType;
    },
    // 保存
    handleSaveBtn() {
      if (!this.myOption) {
        this.$message.error("您没有操作权限！");
        return;
      }

      if (!this.imageId) {
        this.$message.warning("请上传桥梁图例");
        return;
      }
      this.$refs.operateForm.validate((valid) => {
        if (valid) {
          let data = {
            contractCode: this.operateForm.contractCode, // 合同编号
            name: this.operateForm.name, // 名称
            standard: this.operateForm.standard, // 施工测量标准
            projectId: this.projectId, // 桥梁id
            mileageBig: this.operateForm.mileageBig, // 大里程方向
            mileageSmall: this.operateForm.mileageSmall, // 小里程方向
            imageId: this.imageId
          };
          this.$axios.post(`${this.baseURL}section/info`, data).then((res) => {
            if (res.status == "200") {
              this.$message.success("保存成功");
            }
          });
        }
      });
    },
    // 取消
    handleCancelBtn() {
      if (!this.myOption) {
        this.$message.error("您没有操作权限！");
        return;
      }
      this.operateForm = {};
      this.$refs.operateForm.resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
.construction-section {
  width: 100%;
  height: 100%;
  padding: 30px;
  .btn-wrapper {
    display: flex;
    line-height: 32px;
    justify-content: flex-end;
    .download-div:hover {
      cursor: pointer;
    }
    .cancel-btn {
      margin-left: 20px;
      background: none;
      border: 1px solid #2667db;
    }
  }
  .content-wrapper {
    margin-top: 10px;
    ::v-deep .my-form .el-form-item__label {
      font-size: 14px !important;
      font-weight: 400;
    }
    ::v-deep .el-input__inner {
      height: 34px;
    }
    .upload-wrapper {
      display: flex;
      align-items: center;
      .label-name {
        width: 150px;
        font-size: 14px;
        font-weight: 400;
        text-align: right;
        padding-right: 12px;
        .tips-text {
          color: #f5686b;
          margin-right: 5px;
        }
      }
      .upload-demo {
        width: 70px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #192c52;
        &:hover {
          cursor: pointer;
        }
        ::v-deep .el-upload.el-upload--text {
          width: 100%;
          height: 100%;
          i {
            color: #3d6391;
          }
        }
      }
      img {
        width: 45%;
        margin-left: 20px;
      }
    }
  }
}
::v-deep div.el-input .el-input__inner {
  background: #0c265a !important;
  border: 1px solid rgba(38, 103, 219, 0.5);
}
</style>

<template>
  <div class="point-manage-page">
    <div class="box center search-wrapper">
      <div class="box center search">
        <el-select v-model="setStatus" placeholder="请选择设置状态">
          <el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
        <el-select v-model="profileId" placeholder="请选择截面">
          <el-option v-for="item in profileOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
        <el-select v-model="position" placeholder="请选择位置">
          <el-option v-for="item in siteOptions" :key="item.id" :label="item.site" :value="item.id"> </el-option>
        </el-select>
        <el-button @click="handleSearch">查询</el-button>
        <el-button class="reset-btn" @click="handleResetClick">重置</el-button>
      </div>
      <div class="btn-wrapper">
        <div @click="downloadTemplate" class="download-div" :class="!myUpload ? 'disable_icon' : ''">
          <i class="el-icon-download"></i>
          <span class="download-text">下载模板</span>
        </div>
        <el-upload class="upload-demo" :headers="{ token }" :on-success="onsuccess" ref="upload" :data="updata" :action="importUrl" :show-file-list="false">
          <el-button class="import-btn" @click="handleImportBtn" :class="!myUpload ? 'disable_icon' : ''">导入</el-button>
        </el-upload>
      </div>
    </div>
    <div class="table-wrapper">
      <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#04153F', color: '#fff' }" :data="tableData">
        <!-- <el-table-column type="index" width="80" label="序号"></el-table-column> -->
        <el-table-column prop="code" label="测点编号" width="230"></el-table-column>
        <el-table-column prop="pierName" label="墩号"></el-table-column>
        <el-table-column prop="linkName" label="节段"></el-table-column>
        <el-table-column prop="profileName" label="截面"></el-table-column>
        <el-table-column prop="siteName" label="位置"></el-table-column>
        <el-table-column prop="value" label="理论值" width="240"></el-table-column>
        <el-table-column prop="site" label="位置描述" width="230px" align="left"></el-table-column>
        <el-table-column prop="id" label="操作">
          <template slot-scope="scope">
            <span class="operate-txt" style="margin-right: 10px" @click="handleEdit(scope.row)" :class="!myOption ? 'disable_icon' : ''">修改</span>
          </template>
        </el-table-column>
      </el-table>
      <common-pagination v-show="tableData.length > 0" class="pageMain" :pageSize="pageSize" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </div>
    <!-- 编辑 -->
    <el-dialog title="修改" :visible.sync="addDialogVisible" class="my-dialog" top="10vh" :before-close="handleDialogClose">
      <div class="add-wrapper">
        <el-form :model="operateForm" :rules="formRules" ref="operateForm" label-width="100px" class="my-form">
          <el-form-item label="测点编号" prop="code">
            <span>{{ operateForm.code }}</span>
          </el-form-item>
          <el-form-item label="截面" prop="profileId">
            <el-select v-model="operateForm.profileId" placeholder="请选择截面">
              <el-option v-for="item in profileOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="位置" prop="siteId">
            <el-select v-model="operateForm.siteId" placeholder="请选择位置">
              <el-option v-for="item in siteOptions" :key="item.id" :label="item.site" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- <el-divider class="my-divider"></el-divider> -->
        <!-- <div class="content">
                    <el-table :data="dialogTableData">
                        <el-table-column prop="mileage" width="110" label="方向"></el-table-column>
                        <el-table-column prop="linkName" label="施工节段"></el-table-column>
                        <el-table-column prop="workingName" label="工况"></el-table-column>
                        <el-table-column prop="value" label="理论值" width="130">
                            <template slot-scope="scope">
                                <el-input style="width: 100%" v-model="scope.row.value"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column width="30"></el-table-column>
                    </el-table>
                </div> -->
        <div class="btn-footer">
          <el-button class="save-btn" @click="handleSave">保存</el-button>
          <el-button class="cancel-btn" @click="handleDialogClose">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
import axios from "axios";
export default {
  name: "pointManage",
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      token: window.sessionStorage.getItem("token"),
      // 设置状态
      setStatus: "",
      statusOptions: [
        {
          id: 0,
          name: "未设置"
        },
        {
          id: 1,
          name: "已设置"
        }
      ],
      // 截面
      profileId: "",
      profileOptions: [],
      // 位置
      position: "",
      siteOptions: [],
      // 分部工程
      branchId: "",
      branchOptions: [],
      // 施工节段
      linkId: "",
      linkOptions: [],
      // 测点
      code: "",
      total: 0,
      currentPage: 1,
      pageSize: 10,
      // 表格数据
      tableData: [],
      // 操作类型
      operateType: "",
      addDialogVisible: false,
      operateForm: {},
      // 监测类型
      typeOptions: [
        {
          id: 1,
          name: "线形"
        },
        {
          id: 2,
          name: "变形"
        },
        {
          id: 3,
          name: "标高"
        },
        {
          id: 4,
          name: "偏位"
        },
        {
          id: 5,
          name: "预压"
        }
      ],
      // 校验规则
      formRules: {
        profileId: [{ required: true, message: "请选择截面", trigger: "blur" }],
        siteId: [{ required: true, message: "请选择位置", trigger: "blur" }]
      },
      // 施工标段名称
      sectionName: "",
      // 当前操作行
      currentRow: {},
      updata: {},
      importUrl: "",
      // 编辑弹窗 表格
      dialogTableData: []
    };
  },
  created() {
    this.getAllProfileData();
    this.getAllSiteData();
    this.getTableData();
  },
  mounted() {
    this.importUrl = `${this.baseURL}section/import/target/${this.projectId}`;
  },
  methods: {
    // 获取所有截面 自动化监测
    getAllProfileData() {
      // type：1 人工测点 2 自动监测测点
      this.$axios.get(`${this.baseURL}section/profile/list/${this.projectId}?type=2`).then((res) => {
        console.log("获取全部截面管理数据", res);
        this.profileOptions = res.data.data;
      });
    },
    // 获取所有位置 自动化监测
    getAllSiteData() {
      this.$axios.get(`${this.baseURL}section/site/list/${this.projectId}?type=2`).then((res) => {
        console.log("获取位置管理数据", res);
        this.siteOptions = res.data.data;
      });
    },
    // 获取表格数据
    getTableData() {
      let path = `section/target/page?pageNum=${this.currentPage}&pageSize=${this.pageSize}&projectId=${this.projectId}&profileId=${this.profileId}&siteId=${this.position}&status=${this.setStatus}`;
      this.$axios.get(`${this.baseURL}${path}`).then((res) => {
        console.log("获取自动化监测数据", res);
        let detail = res.data.data;
        this.tableData = detail.records;
        this.total = detail.total;
      });
    },
    // 查询
    handleSearch() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 重置
    handleResetClick() {
      this.setStatus = "";
      this.profileId = "";
      this.position = "";
      this.currentPage = 1;
      this.getTableData();
    },
    // 下载模板
    downloadTemplate() {
      if (!this.myUpload) {
        this.$message.error("您没有下载权限！");
        return;
      }
      axios({
        url: `${this.baseURL}section/download`,
        method: "put",
        responseType: "blob",
        params: { type: 9 }, // 自动化监测模板 传9
        headers: {
          token: this.token
        }
      })
        .then((res) => {
          console.log("导出模板", res);

          let a = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          let objectUrl = URL.createObjectURL(blob);
          let name = "自动化监测导入模板";
          a.setAttribute("href", objectUrl);
          a.setAttribute("download", `${name}.xlsx`);
          a.click();
        })
        .catch((err) => {});
    },
    // 查看详情
    getDetail(id) {
      this.$axios.get(`${this.baseURL}section/target/info/${id}`).then((res) => {
        if (res.status == "200") {
          console.log("自动化监测设置详情", res);
          this.operateForm = res.data.data;
          // this.dialogTableData = res.data.data.list
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 编辑
    handleEdit(row) {
      if (!this.myOption) {
        this.$message.error("您没有修改权限！");
        return;
      }
      this.operateType = "edit";
      this.addDialogVisible = true;
      this.getDetail(row.targetId);
      this.currentRow = row;
    },
    // 保存
    handleSave() {
      this.$refs.operateForm.validate((valid) => {
        if (valid) {
          // this.dialogTableData.forEach(item => {
          //     if (item.value) {
          //         item.value = Number(item.value)
          //     }
          // })
          let data = {
            profileId: this.operateForm.profileId, // 截面id
            siteId: this.operateForm.siteId, // 位置id
            targetId: this.currentRow.targetId, // 传感器id
            list: []
            // list: this.dialogTableData // 表格数据
          };
          console.log(data);
          this.$axios.post(`${this.baseURL}section/target/save/${data.targetId}`, data).then((res) => {
            if (res.data.status == "200") {
              this.$message.success("修改成功");
              this.addDialogVisible = false;
              this.operateForm = {};
              this.getTableData();
            } else {
              this.$message.error(res.data.errMsg);
            }
          });
        }
      });
    },
    // 选择截面后 理论值表格更新
    getDialogTableDataById(targetId, profileId) {
      this.$axios.get(`${this.baseURL}section/target/profile/${targetId}/${profileId}`).then((res) => {
        if (res.status == "200") {
          console.log("根据截面和测点查询自动化监测设置表格详情", res);
          this.dialogTableData = res.data.data;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 切换截面
    handleProfileChange(val) {
      this.getDialogTableDataById(this.currentRow.targetId, val);
    },
    // 导入
    handleImportBtn() {
      if (!this.myUpload) {
        this.$message.error("您没有导入权限！");
        return;
      }
      this.updata = {
        projectId: this.projectId
      };
    },
    // 导入成功
    onsuccess(res, file, fileList) {
      // console.log(res, file, fileList)
      if (res.status == 200) {
        this.$message.success("导入成功");
        this.currentPage = 1;
        this.getTableData();
      } else {
        this.$message.error(res.errMsg);
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    // 关闭弹窗
    handleDialogClose() {
      this.addDialogVisible = false;
      this.$refs.operateForm.resetFields();
      this.operateForm = {};
    },
    // 格式化监测类型
    formatType(row, column) {
      let type = row.type;
      let str = "";
      this.typeOptions.forEach((item) => {
        if (item.id == type) {
          str = item.name;
        }
      });
      return str;
    }
  },
  components: {
    CommonPagination
  }
};
</script>

<style lang="scss" scoped>
::v-deep .my-dialog div.el-dialog {
  .el-dialog__body {
    padding: 0 20px 30px 20px;
    color: #fff;
    .add-wrapper {
      border-top: 1px solid rgba(204, 218, 255, 0.3);
      padding-top: 30px;
      .my-divider {
        width: 94%;
        margin-left: 3%;
        background: rgba(204, 218, 255, 0.3);
      }
      .content {
        height: 360px;
        margin-bottom: 48px;
        .el-table__body-wrapper {
          height: 300px;
          overflow-y: auto;
        }
      }
    }
  }
}
.point-manage-page {
  width: 100%;
  height: 100%;
  padding: 30px 30px 10px 30px;
  display: flex;
  flex-direction: column;
  .search-wrapper {
    line-height: 32px;
    justify-content: space-between;
    margin-bottom: 30px;
    .search .el-select {
      width: 180px;
      margin-right: 20px;
      ::v-deep .el-input {
        height: 32px;
      }
    }
    .btn-wrapper {
      display: flex;
      .download-div:hover {
        cursor: pointer;
      }
      .download-div {
        display: flex;
        align-items: center;
        .download-text {
          font-size: 14px;
          color: #cbe1fc;
        }
        i {
          font-size: 20px;
          color: #2772f0;
        }
      }
      .el-button {
        margin-left: 20px;
      }
    }
  }
  .table-wrapper {
    flex: 1;
    overflow: hidden;
    .el-table {
      height: calc(100% - 50px);
      overflow: auto;
    }
  }
}
::v-deep .hui1 {
  background: #091d46 !important;
}
::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  text-align: center;
}
.common-page.pageMain {
  margin-top: 15px;
}
::v-deep .search-wrapper div.el-input .el-input__inner {
  width: 180px;
  height: 32px;
  margin-right: 15px;
}
::v-deep .el-dialog div.el-table th {
  background-color: transparent !important;
}
::v-deep .el-dialog div.el-input .el-input__inner {
  border: 1px solid #2667db;
}
</style>
